<script setup lang="ts">
const { closeModal } = useModalStore();
const { isLoading, setLoadingPending, setLoadingSuccess, setLoadingFailed } =
  useLoading();
const $api = useApi();
const localePath = useLocalePath();

const props = defineProps({
  food: {
    type: Object,
    default: () => {},
    required: true,
  },
  ingredient: {
    type: Object,
    default: () => {},
  },
  onConfirm: {
    type: Function,
    default: () => {},
  },
});

const quantity = ref();
watch(
  () => props.ingredient,
  () => {
    quantity.value = Number(props.ingredient.quantity_percent);
  },
  { immediate: true },
);

async function handleUpdateIngredientFromFood() {
  await $api.put(
    `/industrialfoods/${props.food.id}/ingredients/${props.ingredient.id}`,
    {
      quantity: quantity.value,
    },
  );
  props.onConfirm(props.ingredient.id);
  closeModal();
}

async function handleDeleteIngredientFromFood() {
  await $api.delete(
    `/industrialfoods/${props.food.id}/ingredients/${props.ingredient.id}`,
  );
  props.onConfirm(props.ingredient.id);
  closeModal();
}
</script>

<template>
  <BaseModal
    :title="`Editer une portion à l'aliment ${props.food.label}`"
    :width="900"
  >
    <div>
      <h3>{{ props.ingredient.label }}</h3>

      <BaseButton
        text="Aller à l'aliment"
        @click="
          navigateTo(
            localePath('/backoffice/rawfoods/' + props.ingredient.rawfoodId),
          )
        "
      />
    </div>

    <BaseInput
      type="number"
      label="Quantité (%)"
      v-model="quantity"
      required
    ></BaseInput>

    <template #footer>
      <BaseButton text="Annuler" type="secondary" @click="closeModal" />

      <BaseButton
        text="Supprimer"
        type="warning"
        @click="handleDeleteIngredientFromFood"
      />
      <BaseButton
        text="Mettre à jour"
        @click="handleUpdateIngredientFromFood"
      />
    </template>
  </BaseModal>
</template>

<style scoped></style>
